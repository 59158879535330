export const textEn =
  {
    dec:"Risk",
    ind:"Indicators",
    edu:"Education",
    buy:"Buy",
    con:"Contact",
  };


export const textEs =
  {
    dec:"Declaraciones",
    ind:"Indicadores",
    edu:"Educación",
    buy:"Comprar",
    con:"Contacto",
    };
