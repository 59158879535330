export const textEn =
  {
    tit:"Power your trading with our indicators",
    sub:"Discover a new dimension to your trading strategy with our advanced indicators, designed for accuracy and performance.",
  };


export const textEs =
  {
    tit:"Potencia tu Trading con Nuestros Indicadores",
    sub:"Descubre una nueva dimensión en tu estrategia de trading con nuestros indicadores avanzados, diseñados para ofrecer precisión y rendimiento.",
    };
