export const textEn = {
  titg1: "1. Indicator Installation",
  tit1: "Normal Installation",
  tit2: "Installation with Exceptions (Recommended)",
  subt2: "In certain cases, antivirus software may identify the files as false positives. To resolve this issue, it is recommended to follow these steps:",
  tit3: "Uninstall Indicators",
  subt3: "",
  titg2: "2. Common Errors",
  tit4: "The indicator is not displayed on the chart, but the side button is visible.",
  subt4: "- Check for any errors while loading the indicator and obtain the code. To do this, follow the steps in the video.",
  tit5: "The indicator does not show any content, not even the side button.",
  subt5: "- Check the error while loading the indicator and follow the steps in the video.",
};



export const textEs = {
  titg1: "1. Instalación de indicadores",
  tit1: "Instalación normal",
  tit2: "Instalación con excepciones (recomendada)",
  subt2: "En ciertos casos, el software antivirus puede identificar los archivos como falsos positivos. Para solucionar este problema, se recomienda seguir estos pasos:",
  tit3: "Desinstalar indicadores",
  subt3: "",
  titg2: "2. Errores Comunes",
  tit4: "El indicador no se muestra en el gráfico, pero el botón lateral está visible.",
  subt4: "- Verificar que no haya errores al cargar el indicador y obtener el código. Para esto, sigue los pasos del video.",
  tit5: "El indicador no muestra ningún contenido, ni siquiera el botón lateral.",
  subt5: "- Verificar el error al cargar el indicador y seguir los pasos del video.",
};